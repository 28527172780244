import { React, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faThumbsUp, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import FeaturesContent from '../components/FeaturesContent';
import Landscape from '../assets/images/landscape.webp'
import Integration from '../vectors/Integration';

const Payroll = () => {
  const strongPoints = [
    'HMRC compliant',
    'Pensions integration with NEST',
    'Employee payslip portal',
    '30 day FREE trial for any business'
  ];

  return (
    <FeaturesContent title="Payroll management worry free" icon={faThumbtack}>
      <div className="text-xl py-5 md:py-10">
        <img className="float-right ml-4 my-2 w-1/2 rounded-full shadow-2xl" src={Landscape} alt="Payroll" />
        <ul className='ml-8'>
          {strongPoints.map((point, index) => {
            return (
              <li key={index} className="flex items-center">
                <FontAwesomeIcon icon={faCheckSquare} className="mr-4 text-blue-600" />
                <p className="text-gray-800 dark:text-gray-200">{point}</p>
              </li>
            );
          })}
        </ul>
        <p className="my-8 font-light">
          We have partnered with <a href="https://www.shapepayroll.com/" target="_blank" >Shape payroll</a> , a great 
          UK based payroll management software.
          They offer a secure modern cloud-based system that allows to access your payroll
          24 hours a day from anywhere.

          Similar to Punch-in All software upgrades are taken care of so
          you're always in line with latest regulation and rate changes, leaving you
          nothing to worry about.
        </p>
        {/*<p className="my-8 font-light">With Shape Payroll you will be able to access dedicated support specialists who
          will be with you every step of the way.
        </p>*/}
        <p className="my-8 font-light">You may also rely on implementation specialists
          on our end as well as Shape end to taylor and automate processes to
          your requirements. All various aspects of the payroll process are taken care
          seamlessly with Shape and Punch in</p>
        <p className="my-8 font-light">Shape integrates with the full UK Government API
          so you can fully automate your compliance. Shape uses best-in-class encryption and security practices to protect your information.</p>
        <h2 className="md:text-xl xl:text-2xl leading-none w-full py-5 md:pt-10 px-2">
          Our value proposition
        </h2>
        <div>
          <div className="flex m-4">
            <div className="w-10 mr-4">
              <Integration />
            </div>
            <div className="feature-copy__Content-jstgxl-2 kICOEY">
              <h3 className="feature-copy__H-jstgxl-3 dwINMm">Seamless integrated</h3>
              <p className="font-light">Employees' time sheets will be integrated with the payroll platform on a real time basis so that 
                they can be immediately processed by the payroll management, be it your in-house staff or Shape. Employees have access 
                to their worked hours and payroll information at any time via their user portal.
              </p>
            </div>
          </div>
          <div className="flex m-4">
            <div className="w-10 mr-4">
              <svg style={{width: '2rem', height: '2rem'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.89 41.89">
                <g transform="translate(-5774.154 -1923.83)" fill="none" stroke="#eb704c">
                  <circle cx="20.445" cy="20.445" r="20.445" transform="translate(5774.654 1924.33)" strokeWidth="1"></circle>
                  <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.916">
                    <path d="M5785.05,1944.775a10.048,10.048,0,0,1,10.049-10.048"></path>
                    <path d="M5805.148,1944.775a10.049,10.049,0,0,1-10.049,10.049"></path>
                    <path d="M5801.031,1946.834l4.117-4.117,4.117,4.117"></path>
                    <path d="M5789.167,1942.717l-4.117,4.117-4.117-4.117" fill="none" stroke="#eb704c"></path>
                  </g>
                </g>
              </svg>
            </div>
            <div>
              <h3 className="feature-copy__H-jstgxl-3 dwINMm">Streamline payroll processes</h3>
              <p className="font-light">Cut out manual processes and stress by automatically syncing all your company HR data instantly.</p>
            </div>
          </div>
          <div className="flex m-4">
            <div className="w-10 mr-4">
              <svg style={{width: '2rem', height: '2rem'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.89 41.89"><g transform="translate(-319.125 -6332.645)">
                <circle cx="20.445" cy="20.445" r="20.445" transform="translate(319.625 6333.145)" fill="none" stroke="#eb704c" strokeWidth="1"></circle><path d="M350.174,6345.382a.882.882,0,0,0-.994.413l-3.458,5.987H345.1l-4.262-7.382a.884.884,0,0,0-1.531,0l-4.263,7.382h-.625l-3.457-5.987a.884.884,0,0,0-1.65.442v14.6a.885.885,0,0,0,.884.885h19.752a.884.884,0,0,0,.884-.885v-14.6A.885.885,0,0,0,350.174,6345.382Z" fill="#eb704c"></path></g></svg>
            </div><div className="feature-copy__Content-jstgxl-2 kICOEY">
              <h3 className="feature-copy__H-jstgxl-3 dwINMm">Simplify legal compliance</h3>
              <p className="font-light">With real-time integrations to HMRC, Shape handles your tax and compliance work, keeping your business compliant with the right forms, laws, and regulations.</p>
            </div>
          </div>
          <div className="flex m-4">
            <div className="w-10 mr-4">
              <svg style={{width: '2rem', height: '2rem'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.89 41.89"><g transform="translate(-5774.154 -2284.946)"><circle cx="20.445" cy="20.445" r="20.445" transform="translate(5774.654 2285.446)" fill="none" stroke="#eb704c" strokeWidth="1"></circle><g fill="#eb704c"><path d="M1.157,0H5.543A1.157,1.157,0,0,1,6.7,1.157V15.463a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1.157A1.157,1.157,0,0,1,1.157,0Z" transform="translate(5783.182 2299.178)"></path><path d="M1.157,0H5.543A1.157,1.157,0,0,1,6.7,1.157V20.41a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1.157A1.157,1.157,0,0,1,1.157,0Z" transform="translate(5791.576 2294.231)"></path><path d="M1.157,0H5.543A1.157,1.157,0,0,1,6.7,1.157V10.3a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1.157A1.157,1.157,0,0,1,1.157,0Z" transform="translate(5799.969 2304.341)"></path></g></g></svg>
            </div>
            <div className="feature-copy__Content-jstgxl-2 kICOEY">
              <h3 className="feature-copy__H-jstgxl-3 dwINMm">Enhance reporting</h3>
              <p className="font-light">Effortlessly report on total pay data in shape or your existing analytics tools to ensure your labour costs are always on track.</p>
            </div>
          </div>
        </div>
      </div>
    </FeaturesContent >
  );
}

export default Payroll;
